import React, { useState } from "react";
import "./styles.css";
import { Slide, AttentionSeeker } from "react-awesome-reveal";

import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { Padding } from "@mui/icons-material";
import { padding, width } from "@mui/system";

const LiveChat = () => {
  const [allButtonDisplayClass, setAllButtonDisplayClass] = useState("d-block");
  const [triggerButtonDisplayClass, setTriggerButtonDisplayClass] =
    useState("d-block");

  return (
    <div className="live_chat_section_wrapper">
      <Slide direction="right" duration={1500}>
        <div className={allButtonDisplayClass}>
          <div className="all_btn">
            <a href="https://wa.me/+8801719460044">
              {" "}
              <i className="fa-brands fa-whatsapp social_chat_btn"></i>
            </a>
            <a href="https://m.me/zenithsafetysolution">
              {" "}
              <i className="fa-brands fa-facebook-messenger social_chat_btn"></i>
            </a>
            <a href="https://ig.me/m/zenith_safety_solution">
              {" "}
              <i className="fa-brands fa-instagram social_chat_btn"></i>
            </a>
            <a href="mailto: sales@zenithsafetysolution.com">
              {" "}
              <i className="fa-solid fa-envelope social_chat_btn"></i>
            </a>
          </div>
        </div>
      </Slide>
      <br />
      {/* <div className={triggerButtonDisplayClass}>
        <div
          className="trigger_btn"
          onClick={() => setAllButtonDisplayClass("d-block")}
        >
          <ChatIcon />
        </div>
      </div> */}
    </div>
  );
};

export default LiveChat;
