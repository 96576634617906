import React from "react";
import { Link } from "react-router-dom";
import "./styles.css";

const ProductCard = (props) => {
  return (
    <>
      <div>
        <Link
          to={"/product/" + props.productId}
          className="card my-2 shadow-2-strong single_product_card"
        >
          <img src={props.imageLink} className="card-img-top" />
          <div className="card-body d-flex flex-column">
            <div className="card-footer">
              <h6 class="card-title">{props.title}</h6>
            </div>
          </div>
        </Link>
      </div>
    </>
  );
};

export default ProductCard;
