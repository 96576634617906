import React from "react";

import { Link } from "react-router-dom";

import "./styles.css";
import ppe_image from "../../assets/img/categories/core_ppe.png";

import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

const PPESkeleton = () => {
  return (
    <div className="ppe_menu text-center">
      <h4>Select Component</h4>
      <hr />

      <img className="category_img" src={ppe_image} alt="" />

      <Tooltip title="Head" placement="top">
        <Link to="/category/12" className="dot head"></Link>
      </Tooltip>
      <Tooltip title="Eye" placement="left">
        <Link to="/category/13" className="dot eye"></Link>
      </Tooltip>
      <Tooltip title="Hearing" placement="right">
        <Link to="/category/14" className="dot hearing"></Link>
      </Tooltip>
      <Tooltip title="Respiratory" placement="bottom">
        <Link to="/category/15" className="dot respiratory"></Link>
      </Tooltip>
      <Tooltip title="Hand" placement="right">
        <Link to="/category/16" className="dot hand"></Link>
      </Tooltip>
      <Tooltip title="Foot" placement="right">
        <Link to="/category/17" className="left_foot"></Link>
      </Tooltip>
      <Tooltip title="Foot" placement="left">
        <Link to="/category/17" className="right_foot"></Link>
      </Tooltip>
    </div>
  );
};

export default PPESkeleton;
