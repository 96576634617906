import React from "react";
import ViewProduct from "./ViewProduct";
import ProductForm from "./ProductForm";

import "./styles.css";
import AlertComponent from "../components/Alert";

const ManageProduct = () => {
  return (
    <div>
      <ProductForm />
      <ViewProduct />
    </div>
  );
};

export default ManageProduct;
