import MobileNav from "./MobileNav";
import Navbar from "./Navbar";
import { Link } from "react-router-dom";

import logo from "../../assets/img/logo/logo.png";

const Header = () => {
  return (
    <header>
      <div className="nav-area fixed-top">
        <Link to="/" className="logo">
          <img src={logo} alt="logo" className="logo_img" />
        </Link>

        {/* for large screens */}
        <Navbar />

        {/* for small screens */}
        <MobileNav />
      </div>
    </header>
  );
};

export default Header;
