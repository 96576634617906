import React from "react";
import { BrowserRouter as Router, Link, Routes, Route } from "react-router-dom";
import "./App.css";

import BodyComponent from "./components/BodyComponent";

import Landing from "./pages/Landing";
import Test from "./Test";
import EntryPoint from "./EntryPoint";
import Product from "./pages/Product";
import ProductComponent from "./components/Product";
import ProductDetails from "./components/ProductDetails";
import { Dashboard } from "@mui/icons-material";
import AdminDashboard from "./Admin/Dashboard";
import ManageProduct from "./Admin/ManageProduct";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Landing />} />

        <Route path="category" element={<Product />}>
          <Route path=":categoryId" element={<ProductComponent />} />
          <Route path=":categoryId/:brand" element={<ProductComponent />} />
        </Route>

        <Route path="product/:productId" element={<ProductDetails />} />

        <Route path="admin" element={<AdminDashboard />}>
          <Route path="product" element={<ManageProduct />} />
        </Route>
      </Routes>
    </Router>
  );
};
export default App;
