import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ProductCard from "../ProductCard";
import axios from "axios";
import "./styles.css";
import LoadingComponent from "../Loading";
const ProductComponent = () => {
  const { categoryId } = useParams();
  const { brand } = useParams();

  const [products, setProducts] = useState([]);

  const [loading, setLoading] = useState(false);

  const fetchProducts = async () => {
    try {
      setLoading(true);
      let url = `${process.env.REACT_APP_API_URL}/productsByCategory/${categoryId}`;
      if (brand) {
        url = url + `?brand=${brand}`;
      }
      let response = await axios.get(url);

      setProducts(response.data.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchProducts();
  }, [categoryId, brand]);

  return (
    <>
      <div className="container">
        <div className="row product_container">
          {loading ? <LoadingComponent /> : <></>}
          {products && products.length > 0 ? (
            products.map((element) => {
              return (
                <div className="col-md-3">
                  <ProductCard
                    productId={element.id}
                    imageLink={
                      process.env.REACT_APP_GALLERY_URL + "/" + element.image
                    }
                    title={element.title}
                  />
                </div>
              );
            })
          ) : (
            <div className="d-flex justify-content-center align-items-center">
              No products
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ProductComponent;
