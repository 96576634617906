import React from "react";

// import Swiper core and required modules
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
  EffectCube,
} from "swiper/modules";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css/bundle";

import "./styles.css";

import slider_image1 from "../../assets/img/slider/slider1.jpeg";
import slider_image2 from "../../assets/img/slider/slider2.jpeg";
import slider_image4 from "../../assets/img/slider/slider4.jpeg";

import awards from "../../assets/img/about_us/awards.jpg";
import expo from "../../assets/img/about_us/expo.jpg";
import rocket from "../../assets/img/about_us/49e0509b-35ae-41ea-9006-dd61a8c3cc27.jpg";
import worker1 from "../../assets/img/about_us/worker1.jpg";
import worker2 from "../../assets/img/about_us/worker2.jpg";
import fire1 from "../../assets/img/about_us/fire1.jpg";
import fire2 from "../../assets/img/about_us/fire2.jpg";

const CubeSlider = () => {
  return (
    <Swiper
      // install Swiper modules
      effect={"cube"}
      grabCursor={true}
      autoplay={{
        delay: 1000,
        disableOnInteraction: false,
      }}
      loop={true}
      cubeEffect={{
        shadow: true,
        slideShadows: true,
        shadowOffset: 20,
        shadowScale: 0.94,
      }}
      modules={[EffectCube, Autoplay]}
      className="cube_slider mt-5"
    >
      <SwiperSlide>
        <img src={awards} alt="" />
      </SwiperSlide>
      <SwiperSlide>
        <img src={expo} alt="" />
      </SwiperSlide>
      <SwiperSlide>
        <img src={rocket} alt="" />
      </SwiperSlide>
      <SwiperSlide>
        <img src={worker1} alt="" />
      </SwiperSlide>
      <SwiperSlide>
        <img src={worker2} alt="" />
      </SwiperSlide>
      <SwiperSlide>
        <img src={fire1} alt="" />
      </SwiperSlide>
      <SwiperSlide>
        <img src={fire2} alt="" />
      </SwiperSlide>
    </Swiper>
  );
};

export default CubeSlider;
