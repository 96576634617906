export const menuItemsData = [
  {
    id: 1,
    title: "PPE",
    url: "#",
    submenu: [
      {
        id: 1,
        title: "Core PPE",
        url: "#",
        submenu: [
          {
            id: 1,
            title: "Head",
            url: "12",
          },
          {
            id: 1,
            title: "Eye & Face",
            url: "13",
          },
          {
            id: 1,
            title: "Hearing Protection",
            url: "14",
          },
          {
            id: 1,
            title: "Respiratory Protection",
            url: "15",
          },
          {
            id: 1,
            title: "Hand Glove",
            url: "16",
          },
          {
            id: 1,
            title: "Foot Protection",
            url: "17",
          },
        ],
      },
      {
        id: 1,
        title: "Height Safety",
        url: "#",
        submenu: [
          {
            id: 1,
            title: "Harness",
            url: "18",
          },
          {
            id: 1,
            title: "Lanyard & Connectors",
            url: "19",
          },
          {
            id: 1,
            title: "Lifeline System",
            url: "20",
          },
        ],
      },

      {
        id: 1,
        title: "Electrical Safety",
        url: "#",
        submenu: [
          {
            id: 1,
            title: "Suits",
            url: "23",
          },
          {
            id: 1,
            title: "Gloves",
            url: "24",
          },
          {
            id: 1,
            title: "Shoes",
            url: "25",
          },
          {
            id: 1,
            title: "Accessories",
            url: "26",
          },
        ],
      },
    ],
  },
  {
    id: 1,
    title: "Fire Detection System",
    url: "#",
    submenu: [
      {
        id: 1,
        title: "Control Panel",
        url: "27",
      },
      {
        id: 1,
        title: "Detector & MCP",
        url: "28",
      },
      {
        id: 1,
        title: "Module",
        url: "29",
      },
      {
        id: 1,
        title: "Sounder",
        url: "30",
      },
    ],
  },
  {
    id: 1,
    title: "Gas Detection",
    url: "#",
    submenu: [
      {
        id: 1,
        title: "Portable",
        url: "10",
        submenu: [
          {
            id: 1,
            title: "Single-Gas",
            url: "31",
          },
          {
            id: 1,
            title: "Multi-Gas",
            url: "32",
          },
        ],
      },
      {
        id: 1,
        title: "Fixed",
        url: "11",
      },
    ],
  },
  {
    id: 1,
    title: "Building Management System",
    url: "#",
  },
];
