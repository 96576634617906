import * as React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import "./styles.css";

const Direction = () => {
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Mission" value="1" />
            <Tab label="Vision" value="2" />
            <Tab label="Our Values" value="3" />
          </TabList>
        </Box>
        <TabPanel value="1" className="tab_text">
          We have always been driven by one mission that is to ensure the safety
          of every individual in the industrial sector. Our company, with its
          wide range of industrial safety products, has been successfully
          catering to the needs of various organizations. But for us, that was
          just the beginning. To empower individuals and businesses with
          cutting-edge safety solutions, ensuring the well-being of our
          customers by providing top-quality safety tools and unparalleled
          customer service.
        </TabPanel>
        <TabPanel value="2" className="tab_text">
          Our vision is to expand our reach and provide the best safety measures
          to every industry. With a team of dedicated professionals, we strive
          to bring in innovative safety solutions and set new standards in the
          market. Becoming the trusted global leader in customer-centric safety
          trading, revolutionizing the way people and organizations prioritize
          protection and security in their daily lives. Through igniting a
          passion for safety across the globe, we have a vision to redefine the
          way people approach workplace and everyday safety. By offering the
          most innovative, customer-centric safety tools and resources, we
          strive to become the trusted partner that keeps communities safe and
          secure.
        </TabPanel>
        <TabPanel value="3" className="tab_text">
          Our steadfast commitment to quality and reliability has positioned us
          as on the go choice for all safety requirements. As we continue to
          expand, our persistent mission and vision center on establishing every
          workplace as a safe and secure environment for all. Our values are the
          compass that guides our actions, decisions, and interactions.
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export default Direction;
