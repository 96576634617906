import React from "react";
import { Link } from "react-router-dom";
import "./styles.css";

const SingleProductMini = (props) => {
  return (
    <div className="d-flex mb-3 product_card_mini">
      <Link to={"/product/" + props.productId} className="me-3">
        <img
          src={props.image}
          style={{ minWidth: 96, height: 96 }}
          className="img-md img-thumbnail"
        />
      </Link>

      <Link
        to={"/product/" + props.productId}
        className="mb-1 product_card_mini_title"
      >
        {props.title}
      </Link>
    </div>
  );
};

export default SingleProductMini;
