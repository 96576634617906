import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";

export default function ViewProduct() {
  const [products, setProducts] = useState([]);

  const fetchProducts = async () => {
    try {
      let response = await axios.get(
        `${process.env.REACT_APP_API_URL}/products`
      );

      setProducts(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchProducts();
  }, []);

  const columns = [
    { field: "id", headerName: "ID", width: 100 },
    { field: "title", headerName: "Product Name", width: 600 },
    { field: "category_id", headerName: "Category", width: 100 },
    {
      field: "image",
      headerName: "Image",
      width: 200,
      renderCell: (params) => (
        <img
          src={process.env.REACT_APP_GALLERY_URL + "/" + params.value}
          alt="Product"
          style={{ width: 150, height: 150 }}
        />
      ),
    },
    {
      headerName: "Action",
      width: 200,
      renderCell: (params) => (
        <div>
          <a href="" className="btn btn-success mx-2">
            Edit
          </a>

          <a href="" className="btn btn-danger mx-2">
            Delete
          </a>
        </div>
      ),
    },
  ];

  return (
    <div className="container">
      <div className="product_table">
        <DataGrid
          rows={products}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          pageSizeOptions={[10, 20]}
          rowHeight={200}
        />
      </div>
    </div>
  );
}
