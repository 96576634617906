import React from "react";

import "./styles.css";

import honeywell from "../../assets/img/brand/new/honeywell.png";

import kings from "../../assets/img/brand/new/kings.png";
import greystone from "../../assets/img/brand/new/greystone.png";
import drager from "../../assets/img/brand/new/drager.webp";
import bw from "../../assets/img/brand/new/bw.jpg";
import vesda from "../../assets/img/brand/new/vesda.jpg";
import farenhyt from "../../assets/img/brand/new/farenhyt.avif";
import notifier from "../../assets/img/brand/new/notifier.jpg";
import morley from "../../assets/img/brand/new/morley.png";
import safety_jogger from "../../assets/img/brand/new/safety_jogger.png";
import karam from "../../assets/img/brand/new/karam.png";
import north from "../../assets/img/brand/new/north.jpg";
import impact from "../../assets/img/brand/new/impact.png";
import miller from "../../assets/img/brand/new/miller.jpg";
import salisbury from "../../assets/img/brand/new/salisbury.jpg";
import sperian from "../../assets/img/brand/new/sperian.jpg";
import regin from "../../assets/img/brand/new/regin.png";

import Marquee from "react-fast-marquee";

const BrandSlider = () => {
  return (
    <div className="container p-5">
      <div className="section_header">Our Brand Offerings</div>
      <br />
      <hr />
      <br />

      <Marquee speed={100}>
        <img className="brand_logo" src={honeywell} alt="" />
        <img className="brand_logo" src={kings} alt="" />
        <img className="brand_logo" src={greystone} alt="" />
        <img className="brand_logo" src={drager} alt="" />
        <img className="brand_logo" src={bw} alt="" />
        <img className="brand_logo" src={vesda} alt="" />
        <img className="brand_logo" src={farenhyt} alt="" />
        <img className="brand_logo" src={notifier} alt="" />
        <img className="brand_logo" src={morley} alt="" />
        <img className="brand_logo" src={safety_jogger} alt="" />
        <img className="brand_logo" src={karam} alt="" />
        <img className="brand_logo" src={north} alt="" />
        <img className="brand_logo" src={impact} alt="" />
        <img className="brand_logo" src={miller} alt="" />
        <img className="brand_logo" src={salisbury} alt="" />
        <img className="brand_logo" src={sperian} alt="" />
        <img className="brand_logo" src={regin} alt="" />
      </Marquee>
    </div>
  );
};

export default BrandSlider;
