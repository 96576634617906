import React from "react";
import "./styles.css";
import loading_image from "../../assets/img/loading.gif";

const LoadingComponent = () => {
  return (
    <div className="d-flex justify-content-center align-items-center">
      <img className="loading_img" src={loading_image} alt="" />
    </div>
  );
};

export default LoadingComponent;
