import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import axios from "axios";

import "./styles.css";
import SingleProductMini from "../SingleProductMini";
import CategoryMenu from "../CategoryMenu";

const ProductDetails = () => {
  const { productId } = useParams();

  const [product, setProduct] = useState({});
  const [products, setProducts] = useState([]);

  const fetchProductDetails = async () => {
    try {
      let response = await axios.get(
        `${process.env.REACT_APP_API_URL}/products/${productId}`
      );

      console.log(response.data.data);
      setProduct(response.data.data);

      fetchProducts(response.data.data.category_id, response.data.data.id);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchProducts = async (categoryId, productIdToExclude) => {
    try {
      let response = await axios.get(
        `${process.env.REACT_APP_API_URL}/productsByCategory/${categoryId}?limit=3&productIdToExclude=${productIdToExclude}`
      );

      setProducts(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchProductDetails();
  }, [productId]);

  return (
    <>
      <div>
        <CategoryMenu />
        <br />
        <br />
        <br />
        <section className="py-5">
          <div className="container">
            <div className="row gx-5">
              <aside className="col-lg-6">
                <div className="border rounded-4 mb-3 d-flex justify-content-center">
                  <a
                    data-fslightbox="mygalley"
                    className="rounded-4"
                    target="_blank"
                    data-type="image"
                    href={
                      process.env.REACT_APP_GALLERY_URL + "/" + product.image
                    }
                  >
                    <img
                      style={{
                        maxWidth: "100%",
                        maxHeight: "50vh",
                        margin: "auto",
                      }}
                      className="rounded-4 fit"
                      src={
                        process.env.REACT_APP_GALLERY_URL + "/" + product.image
                      }
                    />
                  </a>
                </div>
              </aside>
              <main className="col-lg-6">
                <div className="ps-lg-3">
                  <h4 className="title text-dark">{product.title}</h4>
                  <br />
                  <div className="row">
                    <dt className="col-3">Brand</dt>
                    <dd className="col-9">Honeywell</dd>
                  </div>
                  <br />
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book. It has survived not only five centuries,
                    but also the leap into electronic typesetting, remaining
                    essentially unchanged.
                  </p>
                  <br />
                  <hr />
                </div>
              </main>
            </div>
          </div>
        </section>
        {/* content */}
        <section className="bg-light border-top py-4">
          <div className="container">
            <div className="row gx-4">
              <div className="col-lg-8 mb-4">
                <div className="border rounded-2 p-5 bg-white">
                  {/* Pills navs */}

                  {/* Pills content */}
                  <div className="tab-content" id="ex1-content">
                    <div
                      className="tab-pane fade show active"
                      id="ex1-pills-1"
                      role="tabpanel"
                      aria-labelledby="ex1-tab-1"
                    >
                      <h5>Description</h5>
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book. It has
                        survived not only five centuries, but also the leap into
                        electronic typesetting, remaining essentially unchanged.
                        It was popularised in the 1960s with the release of
                        Letraset sheets containing Lorem Ipsum passages, and
                        more recently with desktop publishing software like
                        Aldus PageMaker including versions of Lorem Ipsum.
                      </p>
                    </div>
                  </div>
                  {/* Pills content */}
                </div>
              </div>
              <div className="col-lg-4">
                <div className="px-0 border rounded-2 shadow-0">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title">Similar items</h5>
                      <br />
                      {products &&
                        products.map((element) => {
                          return (
                            <div className="">
                              <SingleProductMini
                                productId={element.id}
                                image={
                                  process.env.REACT_APP_GALLERY_URL +
                                  "/" +
                                  element.image
                                }
                                title={element.title}
                              />
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default ProductDetails;
