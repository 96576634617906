import React, { useState } from "react";
import "./styles.css";
import { Link, useParams } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";

import gas_portable from "../../assets/img/categories/gas_portable.jpg";
import gas_fixed from "../../assets/img/categories/gas_fixed.png";

const GasDetectorMenu = () => {
  const { categoryId } = useParams();

  const [show, setShow] = useState("d-none");
  return (
    <div className="p-5 text-center gas_menu">
      <h4>Select Brand</h4>
      <hr />

      <div>
        <Link to={`/category/${categoryId}/3`} className="btn btn-lg m-2">
          <img
            className="logo_btn"
            src="https://logos-world.net/wp-content/uploads/2021/02/Honeywell-Logo.png"
            alt=""
          />
        </Link>

        <Link to={`/category/${categoryId}/6`} className="btn btn-lg m-2">
          <img
            className="logo_btn"
            src="https://greatcatalog.net/i/brand/382/draeger-c16.png"
            alt=""
          />
        </Link>
      </div>
      <br />
      <h4>Select Type</h4>
      <hr />

      <div>
        <Link
          className="btn btn-lg btn-outline-secondary m-2"
          onClick={() => setShow("d")}
        >
          Portable
        </Link>

        <Link
          to="/category/11"
          className="btn btn-lg btn-outline-secondary m-2"
          onClick={() => setShow("d-none")}
        >
          Fixed
        </Link>
      </div>

      <div className={show}>
        <Link
          to="/category/31"
          className="btn btn-lg btn-outline-secondary m-2"
        >
          Single-Gas
        </Link>

        <Link
          to="/category/32"
          className="btn btn-lg btn-outline-secondary m-2"
        >
          Multi-Gas
        </Link>
      </div>
    </div>
  );
};

export default GasDetectorMenu;
