import React from "react";
import { Link, Outlet } from "react-router-dom";

const AdminDashboard = () => {
  return (
    <>
      <div className="text-center">
        <Link to="product" className="btn btn-primary">
          Product
        </Link>
      </div>
      <Outlet />
    </>
  );
};

export default AdminDashboard;
