import React from "react";

const ExpertiseCard = (props) => {
  return (
    <div className="expertise_card">
      <img
        src={props.imageLink}
        alt="Expertise Icon"
        className="expertise_icon"
      />
      <h5 className="expertise_title">{props.title}</h5>
      <h6 className="expertise_subtitle">{props.subtitle}</h6>
      <p className="expertise_description">{props.description}</p>
    </div>
  );
};

export default ExpertiseCard;
