import React from "react";

// import Swiper core and required modules
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
  EffectFade,
} from "swiper/modules";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css/bundle";

import "./styles.css";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import syngenta from "../../assets/img/gallery/0f6318f5-8a5d-41c9-9d1f-2e00a6d4186c.jpg";
import notifier from "../../assets/img/gallery/3cdbe76c-7d1b-4d94-b060-37729bb2773a.jpg";
import display from "../../assets/img/gallery/627674d8-3269-4dde-bbc9-6c5a69d5f2a1.jpg";
import forede from "../../assets/img/gallery/f17e5832-8808-4511-ac6e-5394ef3f9158.jpg";

const BasicSLider = () => {
  return (
    <Swiper
      modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay, EffectFade]}
      effect="fade"
      spaceBetween={50}
      slidesPerView={1}
      autoplay={{
        delay: 1500,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      }}
      pagination={{ clickable: true }}
      className="basic_slider"
      id="gallery"
    >
      <SwiperSlide>
        <img src={display} alt="" className="slider_image" />
      </SwiperSlide>
      <SwiperSlide>
        <img src={syngenta} alt="" className="slider_image" />
      </SwiperSlide>
      <SwiperSlide>
        <img src={notifier} alt="" className="slider_image" />
      </SwiperSlide>
      <SwiperSlide>
        <img src={forede} alt="" className="slider_image" />
      </SwiperSlide>
    </Swiper>
  );
};

export default BasicSLider;
