import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ProductCard from "../../components/ProductCard";
import axios from "axios";

import { Link, Outlet } from "react-router-dom";
import CategoryMenu from "../../components/CategoryMenu";

import "./styles.css";
import PPESkeleton from "../../components/PPESkeleton";
import BasicSLider from "../../components/BasicSlider";
import CardSlider from "../../components/CardSlider";
import CopyrightComponent from "../../components/Copyright";
import FireMenu from "../../components/FireMenu";
import GasDetectorMenu from "../../components/GasDetectorMenu";

const Product = () => {
  const { categoryId } = useParams();

  const ppe = [
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "23",
    "24",
    "25",
    "26",
  ];

  const fire = ["27", "28", "29", "30"];

  const gas = ["10", "11", "31", "32"];

  // useEffect(() => {
  //   console.log(ppe.includes(categoryId));
  // }, [categoryId]);

  return (
    <div className="">
      <div className="">
        <CategoryMenu />
      </div>
      <div className="row">
        <div className="col-md-4 interective_menu">
          {ppe.includes(categoryId) ? <PPESkeleton /> : <div> </div>}
          {fire.includes(categoryId) ? <FireMenu /> : <div> </div>}
          {gas.includes(categoryId) ? <GasDetectorMenu /> : <div> </div>}
        </div>
        <div className="col-md-8 product_section">
          <Outlet />
        </div>
      </div>
      <div>
        <CopyrightComponent />
      </div>
    </div>
  );
};

export default Product;
