import React, { useEffect, useState } from "react";
import "./styles.css";

const ProgressBar = () => {
  const [scrollPercentage, setScrollPercentage] = useState(0);
  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      const scrollY = window.scrollY;

      const scrollPercent = (scrollY / (documentHeight - windowHeight)) * 100;
      setScrollPercentage(scrollPercent);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div className="progress-container">
      <div
        className="progress-fill"
        style={{
          width: `${scrollPercentage}%`,
        }}
      ></div>
    </div>
  );
};

export default ProgressBar;
