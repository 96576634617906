import React from "react";
import "./styles.css";

import gas from "../../assets/img/expertise/gas_detector.gif";
import fire_panel from "../../assets/img/expertise/fire_panel.gif";
import building from "../../assets/img/expertise/building.gif";
import testing from "../../assets/img/expertise/testing.gif";
import electrical_safety from "../../assets/img/expertise/electrical.gif";
import height_safety from "../../assets/img/expertise/heigh_safety.gif";
import respiratory from "../../assets/img/expertise/respiratory.gif";
import fire_fighter from "../../assets/img/expertise/fire_fighter.gif";

import ExpertiseCard from "./ExpertiseCard";

const Expertise = () => {
  return (
    <div className="container expertise p-5" id="expertise">
      <div className="section_header">Expertise</div>
      <br />
      <hr />

      <div className="row">
        <div className="col-md-3">
          <ExpertiseCard
            imageLink={gas}
            title="Gas Detector Calibration"
            description="Calibration involves
            exposing the monitor to a known concentration of calibration gas or test gas for a specific
            duration. Routine calibration ensures that your gas monitor performs as expected, protecting
            you from hazardous gases like toxic substances, oxygen deficiency, or combustible gases."
          />
        </div>
        <div className="col-md-3">
          <ExpertiseCard
            imageLink={fire_panel}
            title="Fire Panel Installation"
            description="Fire panels (also known as fire alarm
              control panels) are central components of fire alarm systems. They monitor and manage
              various fire detection devices, such as smoke detectors, heat sensors, photoelectric sensors,
              manual pull stations or call points, different modules & horn strobe."
          />
        </div>
        <div className="col-md-3">
          <ExpertiseCard
            imageLink={building}
            title="Building Automation"
            description="A Building Management System is like a smart building’s brain. This technology connects the various systems within a
            building, including HVAC, lighting equipment, DDC controller, control valve, actuators,
            temperature sensors, humidity sensors, pressure transmuters, thermostat and more."
          />
        </div>
        <div className="col-md-3">
          <ExpertiseCard
            imageLink={testing}
            title="Supply, Installation, Commissioning & Validation"
            description="Skilled
            technicians install and wire the components according to design specifications. Ensure proper functioning, test
            response, performance, and compliance with energy codes"
          />
        </div>

        <div className="col-md-3">
          <ExpertiseCard
            imageLink={electrical_safety}
            title="Electrical Safety"
            description="Electrical safety equipment is crucial for protecting individuals working with or around electrical
            systems. Insulated Gloves, Safety Glasses or Goggles, FlameResistant Clothing, Insulated Tools, Electrical Safety Shoes, Hard Hats etc. adherence to safety
            standards and minimizes the risks of electrical accidents and injuries."
          />
        </div>
        <div className="col-md-3">
          <ExpertiseCard
            imageLink={height_safety}
            title="Height and Rescue"
            subtitle="(Training and Demonstration)"
            description="Falls from heights are a leading cause of workplace injuries. Guardrails, safety nets, lanyards,
            anchorage and personal fall protection equipment (like harnesses) help prevent falls. Rope
            Rescue Equipment includes ropes, carabiners, and descenders aid controlled movement during
            rescues."
          />
        </div>
        <div className="col-md-3">
          <ExpertiseCard
            imageLink={respiratory}
            title="Respiratory & Supply Air Line Solution"
            description="The respiratory system is a vital part of our body responsible for breathing. Wherever hazardous
            substances threaten or hinder safe breathing, you require reliable respiratory protection devices.
            Besides, supplied air respirators are non-freely portable breathing apparatuses."
          />
        </div>
        <div className="col-md-3">
          <ExpertiseCard
            imageLink={fire_fighter}
            title="Fire Fighting Equipment"
            description="Firefighting equipment includes various essential items designed to protect firefighters and
            combat fires effectively such as Fire Helmets, Firefighter Suits, Fire Gloves, Self-Contained
            Breathing Apparatus (SCBA), Fire Boots. "
          />
        </div>
      </div>
    </div>
  );
};

export default Expertise;
