import React from "react";
import "./styles.css";

const GoogleMapLocation = () => {
  return (
    <div className="container text-center">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d228.14845099563937!2d90.37405034931128!3d23.805252964283458!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755c173682fe437%3A0x2cd6f96f729d5149!2sZenith%20Safety%20Solution!5e0!3m2!1sen!2sbd!4v1716633175690!5m2!1sen!2sbd"
        style={{ border: 0 }}
        className="map_container"
        allowFullScreen
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      />
    </div>
  );
};

export default GoogleMapLocation;
