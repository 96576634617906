import React, { useEffect } from "react";
import "./styles.css";

const Statistics = () => {
  useEffect(() => {
    //Runs only on the first render
    //Stat Section Code
    // Code for scroll-triggered animation (place this at the beginning)

    // Get the stat_num elements
    const statNums = document.querySelectorAll(".stat_num");

    // Observer options
    const options = {
      root: null, // Observe the entire viewport
      threshold: 0.5, // Trigger animation when 50% of the element is visible
    };

    // Create a new IntersectionObserver instance
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // Element is visible, start animation
          const statNum = entry.target; // Get the element
          startCountingAnimation(statNum); // Call a separate function to start counting
          observer.unobserve(statNum); // Stop observing after triggering once
        }
      });
    }, options);

    // Observe each stat_num element
    statNums.forEach((statNum) => observer.observe(statNum));

    // ------------------------------------------------------------------

    // Your existing code for the counting animation logic (place it here)

    function startCountingAnimation(statNum) {
      const targetValue = parseInt(statNum.textContent.slice(0, -1)); // Get target value without %
      const percentSign = statNum.textContent.slice(-1); // Get the % sign
      let currentCount = 0;

      const updateCount = () => {
        statNum.textContent = currentCount + percentSign; // Update content with %
        currentCount++;

        if (currentCount > targetValue) {
          clearInterval(intervalId); // Stop counting when target reached
        }
      };

      const intervalId = setInterval(updateCount, 10); // Update every 10 milliseconds
    }
  }, []);

  return (
    <div className="container stats_section p-5">
      <div className="row">
        <div className="col-md-4">
          <div className="stat">
            <h1 className="stat_num">100%</h1>
            <hr />
            <h4 className="stat_name">Reliability</h4>
          </div>
        </div>
        <div className="col-md-4">
          <div className="stat">
            <h1 className="stat_num">100%</h1>
            <hr />
            <h4 className="stat_name">Quality Assurance</h4>
          </div>
        </div>
        <div className="col-md-4">
          <div className="stat">
            <h1 className="stat_num">90%</h1>
            <hr />
            <h4 className="stat_name">Support &amp; Service</h4>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Statistics;
