import React from "react";

import { Link } from "react-router-dom";

const HeroProductCard = (props) => {
  return (
    <Link
      to={props.id === "hash" ? "javascript:void(0)" : "/category/" + props.id}
    >
      <div className="product_card">
        <img src={props.img} alt="" className="product_card_img" />
        <div className="card_hover_button btn btn-light">{props.title}</div>
      </div>
    </Link>
  );
};

export default HeroProductCard;
