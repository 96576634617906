import React, { useState } from "react";
import axios from "axios";

const ProductForm = () => {
  const [product, setProduct] = useState({
    categoryId: "",
    title: "",
    description: "",
    image: null, // To hold the file object if needed
  });

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "image") {
      setProduct({
        ...product,
        image: files[0], // Assuming you want to handle only one file
      });
    } else {
      setProduct({
        ...product,
        [name]: value,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("category_id", product.categoryId);
    formData.append("title", product.title);
    formData.append("description", product.description);
    if (product.image) {
      formData.append("image", product.image);
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/products`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log(response);
      window.alert("Success!");

      // Optionally clear the form after successful submission
      // setProduct({
      //   categoryId: "",
      //   title: "",
      //   description: "",
      //   image: null,
      // });
    } catch (error) {
      console.error("Error submitting form:", error);
      window.alert("Failed!");
    }
  };

  return (
    <form className="product_form" onSubmit={handleSubmit}>
      <div className="mb-3">
        <label htmlFor="categoryId" className="form-label">
          Category ID
        </label>
        <input
          type="text"
          className="form-control"
          id="categoryId"
          name="categoryId"
          placeholder="Category ID"
          value={product.categoryId}
          onChange={handleChange}
        />
      </div>
      <div className="mb-3">
        <label htmlFor="title" className="form-label">
          Product Title
        </label>
        <input
          type="text"
          className="form-control"
          id="title"
          name="title"
          placeholder="Product Title"
          value={product.title}
          onChange={handleChange}
        />
      </div>
      <div className="mb-3">
        <label htmlFor="description" className="form-label">
          Product Description
        </label>
        <textarea
          className="form-control"
          id="description"
          name="description"
          rows={3}
          value={product.description}
          onChange={handleChange}
        />
      </div>

      <div className="mb-3">
        <label htmlFor="formFile" className="form-label">
          Upload File
        </label>
        <input
          className="form-control"
          type="file"
          id="formFile"
          name="image"
          onChange={handleChange}
        />
      </div>

      <div className="text-center">
        <button type="submit" className="btn btn-dark">
          Submit
        </button>
      </div>
    </form>
  );
};

export default ProductForm;
