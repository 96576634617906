import React from "react";
import "./styles.css";
import Direction from "../Direction";
import CubeSlider from "../CubeSlider";

const Insights = () => {
  return (
    <div className="container insight_section p-5" id="about_us">
      <div className="section_header">About Us</div>
      <br />
      <hr />
      <br />

      <div className="row">
        <div className="col-md-6 mt-5">
          <CubeSlider />
        </div>
        <div className="col-md-6 mt-5">
          <Direction />
        </div>
      </div>
    </div>
  );
};

export default Insights;
