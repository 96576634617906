import Header from "./Header";
import "./styles.css";

const CategoryMenu = () => {
  return (
    <div>
      <Header />
    </div>
  );
};

export default CategoryMenu;
