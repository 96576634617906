import React, { useState, useEffect } from "react";
import "./styles.css";
import { Link, useParams } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";

import fire_image from "../../assets/img/categories/fire.jpg";

import notifier from "../../assets/img/brand/new/images-removebg-preview.png";
import farenhyt from "../../assets/img/brand/new/farenhyt-removebg-preview.png";
import morley from "../../assets/img/brand/new/morley.png";

const FireMenu = () => {
  const { categoryId } = useParams();

  return (
    <div className="p-5 text-center fire_menu">
      <h4>Select Brand</h4>
      <hr />

      <div>
        <Link to={`/category/${categoryId}/4`} className="btn btn-lg m-2">
          <img className="logo_btn" src={notifier} alt="" />
        </Link>

        <Link to={`/category/${categoryId}/7`} className="btn btn-lg m-2">
          <img className="logo_btn" src={farenhyt} alt="" />
        </Link>
        <Link to={`/category/${categoryId}/5`} className="btn btn-lg m-2">
          <img className="logo_btn" src={morley} alt="" />
        </Link>
      </div>
      <br />
      <h4>Select Type</h4>
      <hr />

      <div>
        <Link
          to="/category/27"
          className="btn btn-lg btn-outline-secondary m-2"
        >
          Control Panel
        </Link>
        <Link
          to="/category/28"
          className="btn btn-lg btn-outline-secondary m-2"
        >
          Module
        </Link>
        <Link
          to="/category/29"
          className="btn btn-lg btn-outline-secondary m-2"
        >
          Detector
        </Link>
        <Link
          to="/category/30"
          className="btn btn-lg btn-outline-secondary m-2"
        >
          Sounder
        </Link>
      </div>
    </div>
  );
};

export default FireMenu;
