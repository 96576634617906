import React from "react";
import "./styles.css";

const Footer = () => {
  return (
    <div className="info_section container p-5">
      <div className="row">
        <div className="col-md-4 address  mt-3">
          <h3 className>Office</h3>
          <br />
          <p>+8801719460044</p>
          <p>sales@zenithsafetysolution.com</p>
          <p>House-42, Road-07, Senpara Parbata, Mirpur-10, Dhaka-1216</p>
        </div>
        <div className="col-md-4 social text-center mt-3">
          <a href="#" className="fa fa-facebook" />
          <a href="#" className="fa fa-linkedin" />
          <a href="#" className="fa fa-twitter" />
          <a href="#" className="fa fa-instagram" />
        </div>
        <div className="col-md-4 domain text-center mt-3">
          <h3>Zenith Safety Solution</h3>
        </div>
      </div>
    </div>
  );
};

export default Footer;
