import React, { useEffect, useState } from "react";
import "./styles.css";
import logo from "../../assets/img/logo/logo.png";
import barIcon from "../../assets/img/menu_bar.png";
import ProgressBar from "../ProgressBar";

import TextField from "@mui/material/TextField";

import SearchIcon from "@mui/icons-material/Search";

const Header = () => {
  const [iconClass, setIconClass] = useState("d-block");
  const [inputClass, setInputClass] = useState("d-none");
  const toogleButton = () => {
    setIconClass("d-none");
    setInputClass("d-block");
  };
  return (
    <div className="sticky-top">
      <ProgressBar />
      <nav className="navbar navbar-expand-sm">
        <div className="container-fluid">
          <a className="navbar-brand" href="/">
            <img src={logo} alt="logo" className="logo_img" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#mynavbar"
          >
            <img src={barIcon} alt="bar icon" className="bar_icon" />
          </button>
          <div className="collapse navbar-collapse" id="mynavbar">
            <ul className="navbar-nav me-auto">
              <li className="nav-item">
                <a className="nav-link" href="/">
                  Home
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#gallery">
                  Gallery
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#expertise">
                  Expertise
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#about_us">
                  About Us
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#our_clients">
                  Our Clients
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">
                  Projects
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#contact">
                  Contact
                </a>
              </li>
            </ul>
            <a href="#contact" className="btn quote_btn">
              Get a Quote
            </a>
            <div className="custom_spacer"></div>
            <form className="d-flex">
              {/* <input
                className="form-control me-2"
                type="text"
                placeholder="Search"
              /> */}

              <div onClick={toogleButton} className={iconClass}>
                {" "}
                <SearchIcon />
              </div>

              <div className={inputClass}>
                <TextField
                  id="standard-basic"
                  label="Search"
                  variant="standard"
                />
              </div>
            </form>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Header;
