import React from "react";

const CopyrightComponent = () => {
  return (
    <footer className="bg-dark text-white text-center py-3">
      <div className="container">
        <p>© 2024 Zenith Safety Solution. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default CopyrightComponent;
