import React from "react";
import "./styles.css";
const ContactForm = () => {
  return (
    <div className="container contact_us p-5" id="contact">
      <div className="section_header">Let's Connect</div>
      <br />
      <hr />
      <br />
      <h3>Contact us to get a quote</h3>
      <br />
      <div className="cf_parent row">
        <form className="contact_form col-md-6">
          <br />
          <div className="mb-3">
            <input
              type="text"
              className="form-control"
              id="name"
              placeholder="Enter your name"
              aria-describedby="name"
            />
          </div>
          <div className="mb-3">
            <input
              type="email"
              className="form-control"
              id="email"
              placeholder="Enter your email"
              aria-describedby="email"
            />
          </div>
          <div className="mb-3">
            <textarea
              className="form-control"
              id="message"
              aria-describedby="message"
              placeholder="Type your message here..."
            />
          </div>
          <br />
          <div className="text-center">
            <a href className="btn btn-outline-light">
              Send
            </a>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ContactForm;
